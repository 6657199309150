import React from 'react';

import { Link } from '@chakra-ui/react';

interface OutlinedBtnProps {
	text: string;
	href?: string;
	// eslint-disable-next-line react/no-unused-prop-types
	icon?: React.ReactElement;
	width?: string | { base?: string; sm?: string };
}

function OutlinedBtn({ text, href, icon, width }: OutlinedBtnProps) {
	return (
		<Link
			as={Link}
			href={href}
			height="auto"
			width={!width ? 'auto' : width}
			display="flex"
			alignItems="center"
			fontFamily="gabarito"
			justifyContent="center"
			h="53px"
			px={6}
			borderRadius="lg"
			textStyle="lg"
			fontWeight="semibold"
			bg="white"
			borderColor="hsla(0, 0%, 0%, 0.1)"
			borderWidth="1px"
			borderStyle="solid"
			color="neutralDark"
			transition="all 0.2s ease"
			_hover={{ bg: 'gray.50', color: 'neutralDark', textDecoration: 'none' }}
			style={{ margin: '0px' }}
			flex={1}
			minW="fit-content"
			boxShadow="0px 12px 12px -4px hsla(0, 0%, 0%, 0.05)"
		>
			{icon}
			{text}
		</Link>
	);
}

export default OutlinedBtn;
