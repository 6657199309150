import { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

export default function MaxWidthWrapper({ children, isFull = false }: { children: ReactNode; isFull?: boolean }) {
	return (
		<Box
			position="relative"
			width={{ base: isFull ? 'full' : 'calc(100% - 12vw)', md: 'calc(100% - 12vw)' }}
			maxW="1400px"
			marginInline="auto"
		>
			{children}
		</Box>
	);
}
