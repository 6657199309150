import React from 'react';
import Image from 'next/image';

import { Box, Container, Flex, SimpleGrid, Stack, Text, Link } from '@chakra-ui/react';
import LogoTaskgrip from '../../images/logoTaskgrip.svg';

export default function LargeWithAppLinksAndSocial() {
	return (
		<Box bg="white" as="footer">
			<Container
				as={Stack}
				position="relative"
				width="calc(100% - 12vw)"
				maxW="1400px"
				marginInline="auto"
				py={{ base: 16, sm: 24 }}
			>
				<Flex
					flexDir={{ base: 'column', md: 'row' }}
					justify={{ base: 'center', md: 'space-between' }}
					textAlign={{ base: 'center', md: 'start' }}
					align={{ base: 'center', sm: 'start' }}
					gap={12}
				>
					<SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: 8, md: 12, lg: 24 }}>
						<Stack align={{ base: 'center', sm: 'flex-start' }} gap={4} color="black">
							<Image src={LogoTaskgrip} alt="TaskGrip Logo" />
							<Text textStyle="lg" fontWeight="semibold" mt={1}>
								Zavolejte nebo napište!
							</Text>
							<Link
								href="tel: +420 775 930 155"
								style={{
									textDecoration: 'underline',
									lineHeight: '25.2px',
								}}
							>
								+420 775 930 155
							</Link>
							<Link
								href="mailto: info@taskgrip.com"
								style={{
									textDecoration: 'underline',
									lineHeight: '25.2px',
								}}
							>
								info@taskgrip.com
							</Link>
						</Stack>

						<Stack align={{ base: 'center', sm: 'flex-start' }} gap={4}>
							<Text color="black" textStyle="lg" fontWeight="semibold" mb={1}>
								Podmínky
							</Text>
							<Link href="/privacy" color="neutralDarkAlpha">
								Ochrana osobních údajů
							</Link>
							<Link href="/terms-and-conditions" color="neutralDarkAlpha">
								Všeobecné obchodní podmínky
							</Link>
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<Link type="button" data-cc="show-preferencesModal" color="neutralDarkAlpha">
								Nastavení cookies
							</Link>
						</Stack>
					</SimpleGrid>

					<Flex
						h="full"
						mt="auto"
						flexDir={{ base: 'column', sm: 'row', md: 'column', lg: 'row' }}
						gap={6}
						alignItems={{ base: 'center', md: 'flex-start' }}
						justifyContent="flex-end"
					>
						<Link href="https://play.google.com/store/apps/details?id=com.taskgrip&hl=cs" target="_blank">
							<Image
								src="/images/download-google-play.png"
								width={160}
								height={51}
								alt="Download in Google Play"
							/>
						</Link>

						<Link href="https://testflight.apple.com/join/VUVnqhsp" target="_blank">
							<Image
								src="/images/download-app-store.png"
								width={160}
								height={51}
								alt="Download in App Store"
							/>
						</Link>
					</Flex>
				</Flex>
				<Flex
					flexDir={{ base: 'column', sm: 'row' }}
					justifyContent="space-between"
					align={{ base: 'center', sm: 'start' }}
					borderTop="1px"
					borderColor="whiteAlpha/300"
					mt="12"
					pt="6"
				>
					<Text color="neutralDarkAlpha" fontWeight="normal" mb="8px">
						© taskgrip 2024
					</Text>
					<Text color="neutralDarkAlpha" fontWeight="normal">
						Powered by taskgrip team
					</Text>
				</Flex>
			</Container>
		</Box>
	);
}
