'use client';

import React from 'react';
import Image from 'next/image';

import { usePathname } from 'next/navigation';
import { Box, Flex, Link, Stack, useBreakpointValue, useDisclosure, VStack } from '@chakra-ui/react';
import { FiUser } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';
import { RxHamburgerMenu } from 'react-icons/rx';

import MaxWidthWrapper from '@/components/landing/MaxWidthWrapper';
import Footer from '@/components/nav/Footer';
import { Button } from '@/components/ui/button';
import { useUser } from '@/supabase/AuthContext';

import OutlinedBtn from '../landing/buttons/OutlinedBtn';
import SolidBtn from '../landing/buttons/SolidBtn';
import NavUserPopover from './NavUserPopover';
import LogoTaskgrip from '../../images/logoTaskgrip.svg';

interface NavItem {
	label: string;
	href?: string;
	onToggle?: () => void;
}

const NAV_ITEMS = (isLanding: boolean) => [
	{
		label: 'Produkt',
		href: isLanding ? '#product' : '/#product',
	},
	{
		label: 'O nás',
		href: isLanding ? '#about-us' : '/#about-us',
	},
	{
		label: 'Ceník',
		href: isLanding ? '#prices' : '/#prices',
	},
	{
		label: 'Kontakt',
		href: isLanding ? '#contact' : '/#contact',
	},
];

function DesktopNav() {
	const user = useUser();
	const pathname = usePathname();
	const isLanding = pathname === '/cz' || pathname === '/en';

	return (
		<Stack
			w="full"
			direction="row"
			align="center"
			justifyContent="space-between"
			gap={8}
			display={{ base: 'none', xl: 'flex' }}
		>
			<Flex gap={5} ml={16}>
				{NAV_ITEMS(isLanding).map((navItem) => (
					<Link
						href={navItem.href}
						key={navItem.label}
						fontFamily="gabarito"
						fontWeight={500}
						color="neutralDark"
						fontSize="18px"
						py={3}
						px={4}
						_hover={{ bg: 'rgba(234, 248, 243, 0.08)' }}
					>
						{navItem.label}
					</Link>
				))}
			</Flex>

			<Flex gap={6}>
				{!user?.email && <SolidBtn content="Vyzkoušet ZDARMA" href="/login" />}
				{!user?.email && <OutlinedBtn text="Přihlásit se" href="/login" icon={<FiUser />} />}
				{user?.email && (
					<Link
						href="/app"
						fontFamily="gabarito"
						fontWeight={500}
						color="neutralDark"
						fontSize="18px"
						py={3}
						px={4}
						_hover={{ bg: 'rgba(234, 248, 243, 0.08)' }}
					>
						Můj účet
					</Link>
				)}
				{user?.email && <NavUserPopover isApp={false} />}
			</Flex>
		</Stack>
	);
}

function MobileNavItem({ label, href, onToggle }: NavItem) {
	const handleScroll = () => {
		if (onToggle) {
			onToggle();
		}
	};

	return (
		<Link
			href={href}
			key={label}
			onClick={handleScroll}
			fontFamily="gabarito"
			fontWeight={500}
			color="neutralDark"
			fontSize="18px"
			py={3}
			px={4}
			_hover={{ bg: 'rgba(234, 248, 243, 0.08)' }}
		>
			{label}
		</Link>
	);
}

function MobileNav({ onToggle }: { onToggle: () => void }) {
	const user = useUser();
	const pathname = usePathname();
	const isLanding = pathname === '/cz' || pathname === '/en';

	return (
		<Box
			top={0}
			left={0}
			w="100%"
			h="100vh"
			position="fixed"
			display={{ base: 'inlilneBlock', xl: 'none' }}
			bgColor="neutralLight"
			overflow="hidden"
			zIndex={50}
		>
			<Box
				_before={{
					content: '""',
					display: 'inline-block',
					position: 'absolute',
					top: '-34%',
					left: '0',
					w: '634px',
					h: '634px',
					bgColor: 'secondaryLight',
					borderRadius: '100%',
				}}
			/>
			<Flex
				position="fixed"
				w="100%"
				h="100vh"
				p={4}
				display={{ base: 'flex', xl: 'none' }}
				flexDirection="column"
				gap={6}
				alignItems="center"
				justifyContent="center"
				bgColor="neutralLight"
				backdropFilter="blur(200px)"
			>
				<VStack w={{ base: '250px', md: '300px' }} gap={4} align="center">
					{NAV_ITEMS(isLanding).map((navItem) => (
						<MobileNavItem key={navItem.label} {...navItem} onToggle={onToggle} />
					))}
				</VStack>

				{!user?.email ? (
					<>
						<SolidBtn
							width={{ base: '250px', md: '300px' }}
							rounded="lg"
							onClick={onToggle}
							content="Vyzkoušet ZDARMA"
							href="/login"
							isCenter
						/>
						<SolidBtn
							width={{ base: '250px', md: '300px' }}
							rounded="lg"
							onClick={onToggle}
							content={
								<>
									<FiUser />
									Přihlásit se
								</>
							}
							href="/login"
							isCenter
							variant="transparent"
						/>
					</>
				) : null}
				{user?.email ? <NavUserPopover isApp={false} /> : null}
				{user?.email ? (
					<Link
						href="/app"
						fontFamily="gabarito"
						fontWeight={500}
						color="neutralDark"
						fontSize="18px"
						py={3}
						px={4}
						_hover={{ bg: 'rgba(234, 248, 243, 0.08)' }}
					>
						Můj účet
					</Link>
				) : null}
			</Flex>
		</Box>
	);
}

export default function WithSubnavigation({ children }: { children: React.ReactNode }) {
	const pathname = usePathname();
	const { open, onToggle } = useDisclosure();
	const [headerColor, setHeaderColor] = React.useState('rgb(255, 255, 255, 0.7)');

	const variant =
		useBreakpointValue(
			{
				base: 912,
				md: 700,
			},
			{
				fallback: 'md',
			},
		) ?? 0;

	const [scroll, setScroll] = React.useState(false);

	React.useEffect(() => {
		if (pathname === '/en' || pathname === '/cz') {
			if (scroll && !open) {
				setHeaderColor('rgb(255, 255, 255, 0.7)');
			} else {
				setHeaderColor('transparent');
			}
		} else if (open) {
			setHeaderColor('rgb(255, 255, 255, 0.7)');
		} else {
			setHeaderColor('transparent');
		}

		// Function to handle scroll event
		const onScroll = () => {
			if (typeof window !== 'undefined') {
				// Check if the window has been scrolled beyond a certain variant value
				setScroll(window.scrollY > 60);
			}
		};

		// Add scroll event listener
		window.addEventListener('scroll', onScroll);

		// Clean up by removing scroll event listener when component is unmounted
		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, [variant, pathname, scroll, open]);

	return (
		<>
			<Box minH="100vh" position="relative">
				<Flex
					width="100%"
					height={{ base: '64px', md: '96px' }}
					position="fixed"
					top={0}
					bgColor={headerColor}
					zIndex={100}
					backdropFilter="blur(16px)"
					alignItems="center"
				>
					<MaxWidthWrapper>
						<Flex justifyContent="space-between" align="center">
							<a href="/">
								<Image src={LogoTaskgrip} alt="TaskGrip Logo" objectFit="contain" />
							</a>

							<Button
								onClick={onToggle}
								variant="ghost"
								aria-label="Toggle Navigation"
								display={{ xl: 'none' }}
								_active={{ bg: 'transparent' }}
								_focus={{ bg: 'transparent' }}
							>
								{open ? (
									<IoClose color="neutralLight" width={4} height={4} />
								) : (
									<RxHamburgerMenu color="neutralLight" width={6} height={6} />
								)}
							</Button>

							<DesktopNav />
						</Flex>
					</MaxWidthWrapper>
				</Flex>
				{open && <MobileNav onToggle={onToggle} />}
				{children}
			</Box>
			<Footer />
		</>
	);
}
